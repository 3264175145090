/* eslint-disable no-shadow */
import lowcoApi from '@/api/lowco-api';

import mutationsConstants from '../types/company/company.mutations';
import actionsConstants from '../types/company/company.actions';

const state = {
  id: null,
  all: [],
  isLoading: false,
};

const mutations = {
  [mutationsConstants.SET_CURRENT_ID]: (state, id) => {
    state.id = id;
  },
  [mutationsConstants.SET_ALL]: (state, companies) => {
    state.all = companies;
  },
  [mutationsConstants.SET_LOADING]: (state, value) => {
    state.isLoading = value;
  },
  [mutationsConstants.CLEAR]: (state) => {
    state.id = null;
    state.all = [];
    state.isLoading = false;
  },
};

const actions = {
  [actionsConstants.GET_ALL_ADMIN]: async ({ commit }) => {
    commit(mutationsConstants.SET_LOADING, true);

    const result = await lowcoApi.getEnterprises();

    commit(mutationsConstants.SET_ALL, result);
    commit(mutationsConstants.SET_LOADING, false);
  },
  [actionsConstants.GET_ALL_USER]: async ({ commit }, userId) => {
    commit(mutationsConstants.SET_LOADING, true);

    const result = await lowcoApi.getUserCompanies(userId);

    commit(mutationsConstants.SET_ALL, result);
    commit(mutationsConstants.SET_LOADING, false);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
