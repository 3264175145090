const HOME = '/home';
const SIGN_IN = '/signIn';
const SIGN_UP = '/signUp';
const SIGN_UP_SUCCESS = '/signUp/success';
const CONFIRM_EMAIL = '/confirm-email';
const PASSWORD_LOST = '/password-lost';
const INVOICES = '/invoices';
const PROFILE = '/profile';
const CATALOG = '/catalog';
const CGV = '/cgv';
const NEW_ENTERPRISE = '/profile/new-enterprise';
const ADMIN_ENTERPRISES = '/admin/enterprises';
const OFFERS = '/offers';
const SUBSCRIPTION_DETAILS = '/subscription/manage';
const SUBSCRIPTIONS = '/subscriptions';
const SUBSCRIPTION_SUCCESS = '/subscription/success';
const SUBSCRIPTION_CANCELED = '/subscription/canceled';
const LOYALTY_CARD_SCANNER = '/loyalty-card-scanner';
const ANNOUNCEMENTS = '/announcements/manage';
const ONLINE_SHOP_CONFIRMATION = '/online-shop-confirmation';
const SUBSCRIPTION_RECURRENCE_SETUP = '/subscription-recurrence-setup';
const PRINT_CLIENT_QRCODE = '/client-qrcode';

export default {
  HOME,
  SIGN_IN,
  SIGN_UP,
  SIGN_UP_SUCCESS,
  CONFIRM_EMAIL,
  PASSWORD_LOST,
  INVOICES,
  PROFILE,
  CATALOG,
  CGV,
  NEW_ENTERPRISE,
  ADMIN_ENTERPRISES,
  OFFERS,
  SUBSCRIPTION_DETAILS,
  SUBSCRIPTIONS,
  SUBSCRIPTION_SUCCESS,
  SUBSCRIPTION_CANCELED,
  LOYALTY_CARD_SCANNER,
  ANNOUNCEMENTS,
  ONLINE_SHOP_CONFIRMATION,
  SUBSCRIPTION_RECURRENCE_SETUP,
  PRINT_CLIENT_QRCODE,
};
