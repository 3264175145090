const makeThrowError = (errorMapper) => (errors) => {
  if (errors?.length) {
    const mappedErrors = errors.map(errorMapper);
    
    throw mappedErrors;
  }

  const errorList = ['errorMessages.general'];

  throw errorList;
};

export default {
  makeThrowError,
};
