import config from '@/config';

import httpService from '@/services/http.service';
import apiErrorUtils from '@/utils/apiErrors.utils';

const BASE_URL = config.lowcoApiUrl;

const mapError = (error) => {
  switch (error.code) {
    default:
      return 'errorMessages.general';
  }
};

const throwError = apiErrorUtils.makeThrowError(mapError);

const fetchCompanyOffer = (companyId) =>
  httpService
    .get(`${BASE_URL}/companies/${companyId}/offer`)
    .catch(throwError);

const updateCompanyOffer = (id, companyOffer) =>
  httpService
    .put(`${BASE_URL}/company-offers/${id}`, companyOffer)
    .catch(throwError);

const fetchCompanyLoyaltyCardInfo = (companyId) =>
  httpService
    .get(`${BASE_URL}/companies/${companyId}/loyalty-card`)
    .catch(throwError);

const updateCompanyLoyaltyCardType = (companyId, loyaltyCardParams) =>
  httpService
    .post(`${BASE_URL}/companies/${companyId}/loyalty-card`, loyaltyCardParams)
    .catch(throwError);

const subscribeToOffer = (companyId, stripePriceId) =>
  httpService
    .post(`${BASE_URL}/companies/${companyId}/subscribe`, { stripePriceId })
    .catch(throwError);

export default {
  fetchCompanyOffer,
  updateCompanyOffer,
  fetchCompanyLoyaltyCardInfo,
  updateCompanyLoyaltyCardType,
  subscribeToOffer,
};
