const SET_CURRENT = 'setCurrent';
const SET_CURRENT_ID = 'setCurrentId';
const CLEAR = 'clearCompany';
const SET_ALL = 'setAll';
const SET_LOADING = 'setLoading';

export default {
  SET_CURRENT,
  SET_CURRENT_ID,
  CLEAR,
  SET_ALL,
  SET_LOADING,
};
