const FETCH_OFFER = 'fetchOffer';
const FETCH_SUBSCRIPTION = 'fetchSubscription';
const FETCH_LOYALTY_CARD_INFO = 'fetchLoyaltyCardInfo';
const FETCH_ALL = 'fetchAll';

export default {
  FETCH_OFFER,
  FETCH_SUBSCRIPTION,
  FETCH_LOYALTY_CARD_INFO,
  FETCH_ALL,
};
