import { computed } from 'vue';

import cookiesUtils from '@/utils/cookies.utils';
import cookiesConstants from '@/constants/cookies.constants';
import rolesConstants from '@/constants/roles.constants';

const useAuthenticatedUser = () => {
  const { ADMINISTRATOR, CLIENT, BACKOFFICE } = rolesConstants;
  const { USER_INFOS } = cookiesConstants;

  const userInfos = computed(() => cookiesUtils.getUserInfos());

  const isConnected = computed(() => !!cookiesUtils.getCookies(USER_INFOS));
  const username = computed(() => userInfos.value?.username);
  const userId = computed(() => userInfos.value?.userId);
  const profileId = computed(() => userInfos.value?.profileId);
  const role = computed(() => userInfos.value?.role);

  const isAdmin = computed(() => role.value === ADMINISTRATOR);
  const isClient = computed(() => role.value === CLIENT);
  const isBackoffice = computed(() => role.value === BACKOFFICE);

  return {
    username,
    userId,
    profileId,
    role,
    isAdmin,
    isClient,
    isBackoffice,
    isConnected,
  };
};

export default useAuthenticatedUser;
