/* eslint-disable no-shadow */
import subscriptionTypesConstants from '@/constants/companySubscriptionTypes.constants';

import mutationsConstants from '../types/companyOffers/companyOffers.mutations';
import gettersConstants from '../types/companyOffers/companyOffers.getters';
import actionsConstants from '../types/companyOffers/companyOffers.actions';
import loyaltyCardStatusConstants from '@/constants/loyaltyCardStatus.constants';
import companyOffersService from '@/services/companyOffers.service';
import companySubscriptionsService from '@/services/companySubscriptions.service';

const initialState = {
  offer: null,
  subscription: null,
  loyaltyCardInfo: null,
  loading: false,
  error: null,
}

const state = initialState;

const mutations = {
  [mutationsConstants.SET_LOADING]: (state, payload) => {
    state.loading = payload;
  },
  [mutationsConstants.SET_ERROR]: (state, payload) => {
    state.error = payload;
  },
  [mutationsConstants.SET_OFFER]: (state, payload) => {
    state.offer = payload;
  },
  [mutationsConstants.SET_SUBSCRIPTION]: (state, payload) => {
    state.subscription = payload;
  },
  [mutationsConstants.SET_LOYALTY_CARD_INFO]: (state, payload) => {
    state.loyaltyCardInfo = payload;
  },
  [mutationsConstants.CLEAR]: (state) => {
    state.offer = initialState.offer;
    state.subscription = initialState.subscription;
    state.loyaltyCardInfo = initialState.loyaltyCardInfo;
    state.loading = initialState.loading;
    state.error = initialState.error;
  },
};

const actions = {
  [actionsConstants.FETCH_OFFER]: async ({ commit }, companyId) => {
    try {
      commit(mutationsConstants.SET_LOADING, true);
      const result = await companyOffersService.fetchCompanyOffer(companyId);

      commit(mutationsConstants.SET_OFFER, result);
    } catch (err) {
      const [error] = err;

      commit(mutationsConstants.SET_ERROR, error);
    } finally {
      commit(mutationsConstants.SET_LOADING, false);
    }
  },
  [actionsConstants.FETCH_SUBSCRIPTION]: async ({ commit }, companyId) => {
    try {
      commit(mutationsConstants.SET_LOADING, true);
      const result = await companySubscriptionsService.fetchCompanySubscription(companyId);

      commit(mutationsConstants.SET_SUBSCRIPTION, result);
    } catch (err) {
      const [error] = err;

      commit(mutationsConstants.SET_ERROR, error);
    } finally {
      commit(mutationsConstants.SET_LOADING, false);
    }
  },
  [actionsConstants.FETCH_LOYALTY_CARD_INFO]: async ({ commit }, companyId) => {
    try {
      commit(mutationsConstants.SET_LOADING, true);
      const result = await companyOffersService.fetchCompanyLoyaltyCardInfo(companyId);

      commit(mutationsConstants.SET_LOYALTY_CARD_INFO, result);
    } catch (err) {
      const [error] = err;

      commit(mutationsConstants.SET_ERROR, error);
    } finally {
      commit(mutationsConstants.SET_LOADING, false);
    }
  },
  [actionsConstants.FETCH_ALL]: ({ commit, dispatch }, companyId) => {
    commit(mutationsConstants.CLEAR);
    
    dispatch(actionsConstants.FETCH_OFFER, companyId);
    dispatch(actionsConstants.FETCH_SUBSCRIPTION, companyId);
    dispatch(actionsConstants.FETCH_LOYALTY_CARD_INFO, companyId);
  }
};

const getters = {
  [gettersConstants.USE_PREMIUM]: (state) => {
    if (!state.offer) {
      return false;
    }

    return state.offer?.usePremium;
  },
  [gettersConstants.USE_LOYALTY_CARD]: (state) => {
    if (!state.subscription) {
      return false;
    }

    return state.subscription.subscriptionTypeId === subscriptionTypesConstants.LOYALTY;
  },
  [gettersConstants.LOYALTY_CARD_STATUS]: (state) => {
    if (!state.subscription) {
      return loyaltyCardStatusConstants.IS_FREE;
    }

    const useLoyaltyCard = state.subscription 
      && state.subscription.subscriptionTypeId === subscriptionTypesConstants.LOYALTY;

    if (!useLoyaltyCard) {
      return loyaltyCardStatusConstants.IS_FREE;
    }

    if (useLoyaltyCard && !state.loyaltyCardInfo) {
      return loyaltyCardStatusConstants.MUST_BE_SET_UP;
    }
    
    return loyaltyCardStatusConstants.IS_LOYALTY;
  },
  [gettersConstants.CAN_USE_ANNOUNCEMENTS]: (_, getters) => {
    const usesPremium = getters[gettersConstants.USE_PREMIUM];
    const usesLoyaltyCard = getters[
      gettersConstants.LOYALTY_CARD_STATUS
    ] === loyaltyCardStatusConstants.IS_LOYALTY;

    return usesPremium || usesLoyaltyCard;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
