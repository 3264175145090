const SET_LOADING = 'setLoading';
const SET_ERROR = 'setError';
const SET_OFFER = 'setOffer';
const SET_SUBSCRIPTION = 'setSubscription';
const SET_LOYALTY_CARD_INFO = 'setLoyaltyCardInfo';
const CLEAR = 'clear';

export default {
  SET_LOADING,
  SET_ERROR,
  SET_OFFER,
  SET_SUBSCRIPTION,
  SET_LOYALTY_CARD_INFO,
  CLEAR,
};
