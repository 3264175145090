const USE_PREMIUM = 'usePremium';
const USE_LOYALTY_CARD = 'useLoyaltyCard';
const LOYALTY_CARD_STATUS = 'loyaltyCardStatus';
const CAN_USE_ANNOUNCEMENTS = 'canUseAnnouncements';

export default {
  USE_PREMIUM,
  USE_LOYALTY_CARD,
  LOYALTY_CARD_STATUS,
  CAN_USE_ANNOUNCEMENTS,
};
