import rolesConstants from '@/constants/roles.constants';
import routesConstants from '@/constants/routes.constants';

const { ADMINISTRATOR } = rolesConstants;

const adminRoutes = [
  {
    path: routesConstants.ADMIN_ENTERPRISES,
    name: 'AdminEnterprises',
    component: () => import('../views/admin/Enterprises.vue'),
    meta: {
      requiresAuth: [ADMINISTRATOR],
    },
  },
];

export default adminRoutes;
