/**
 * @param {{ [key: string]: any }} propsToUpdate
 */
const mapToPatchRequest = (propsToUpdate) => {
  const entries = Object.entries(propsToUpdate);

  return entries.map(([key, value]) => ({
    op: 'replace',
    path: `/${key}`,
    value,
  }));
};

export default mapToPatchRequest;
