import { createStore, createLogger } from 'vuex';
import VuexPersist from 'vuex-persist';

import company from './module/company';
import companyOffers from './module/companyOffers';
import types from './types';

const vueLocalStorage = new VuexPersist({
  storage: window.localStorage,
  reducer: (state) => ({
    company: {
      id: state.company.id,
    },
  }),
});

const store = createStore({
  modules: {
    [types.COMPANY_NAMESPACE]: company,
    [types.COMPANY_OFFERS_NAMESPACE]: companyOffers,
  },
  plugins: process.env.NODE_ENV === 'production'
    ? [vueLocalStorage.plugin]
    : [vueLocalStorage.plugin, createLogger()],
});

export default store;
