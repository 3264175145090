import { computed } from 'vue';
import { useStore } from 'vuex';

import rootTypes from '@/store/types';
import mutationsConstants from '@/store/types/companyOffers/companyOffers.mutations';
import actionsConstants from '@/store/types/companyOffers/companyOffers.actions';
import gettersConstants from '@/store/types/companyOffers/companyOffers.getters';
import createStorePath from '@/utils/createStorePath.utils';

const useCompanyOffers = () => {
  const store = useStore();

  const pathBuilder = createStorePath(rootTypes.COMPANY_OFFERS_NAMESPACE);

  const offer = computed(() => store.state.companyOffers?.offer);
  const subscription = computed(() => store.state.companyOffers?.subscription);
  const loyaltyCardInfo = computed(() => store.state.companyOffers?.loyaltyCardInfo);
  const isLoading = computed(() => store.state.companyOffers?.loading);
  const error = computed(() => store.state.companyOffers?.error);

  const usePremium = computed(
    () => store.getters[pathBuilder(gettersConstants.USE_PREMIUM)],
  );
  const useLoyaltyCard = computed(
    () => store.getters[pathBuilder(gettersConstants.USE_LOYALTY_CARD)],
  );
  const loyaltyCardStatus = computed(
    () => store.getters[pathBuilder(gettersConstants.LOYALTY_CARD_STATUS)],
  );
  const canUseAnnouncements = computed(
    () => store.getters[pathBuilder(gettersConstants.CAN_USE_ANNOUNCEMENTS)],
  );

  const clear = () => store.commit(pathBuilder(mutationsConstants.CLEAR));

  const fetchOffer = (companyId) => store.dispatch(pathBuilder(actionsConstants.FETCH_OFFER), companyId);
  const fetchSubscription = (companyId) => store
    .dispatch(pathBuilder(actionsConstants.FETCH_SUBSCRIPTION), companyId);
  const fetchLoyaltyCardInfo = (companyId) => store
    .dispatch(pathBuilder(actionsConstants.FETCH_OFFER), companyId);
  const fetchAll = (companyId) => store.dispatch(pathBuilder(actionsConstants.FETCH_ALL), companyId);

  return {
    offer,
    subscription,
    loyaltyCardInfo,
    isLoading,
    error,
    usePremium,
    useLoyaltyCard,
    loyaltyCardStatus,
    canUseAnnouncements,
    clear,
    fetchOffer,
    fetchSubscription,
    fetchLoyaltyCardInfo,
    fetchAll,
  };
};

export default useCompanyOffers;
