import { computed } from 'vue';
import { useStore } from 'vuex';

import createStorePath from '@/utils/createStorePath.utils';
import rootTypes from '@/store/types';
import mutationsConstants from '@/store/types/company/company.mutations';
import actionsConstants from '@/store/types/company/company.actions';

import useAuthenticatedUser from './useAuthenticatedUser';

const useCurrentCompany = () => {
  const store = useStore();
  const { profileId, isAdmin } = useAuthenticatedUser();

  // Store path builder
  const pathBuilder = createStorePath(rootTypes.COMPANY_NAMESPACE);

  // Store states
  const currentCompanyId = computed(() => store.state.company.id);
  const companies = computed(() => store.state.company.all);
  const isFetchingCompanies = computed(() => store.state.company.isLoading);

  // Store state mutations
  const clearCompany = () => store.commit(pathBuilder(mutationsConstants.CLEAR));
  const setLoading = (value) => store.commit(pathBuilder(mutationsConstants.SET_LOADING), value);
  const setCurrentCompanyId = (id) => store
    .commit(pathBuilder(mutationsConstants.SET_CURRENT_ID), id);

  // Store actions
  const fetchAdminCompanies = () => store.dispatch(pathBuilder(actionsConstants.GET_ALL_ADMIN));

  const fetchUserCompanies = () => store
    .dispatch(pathBuilder(actionsConstants.GET_ALL_USER), profileId.value);

  const fetchCompanies = isAdmin.value
    ? fetchAdminCompanies
    : fetchUserCompanies;

  return {
    currentCompanyId,
    companies,
    isFetchingCompanies,
    fetchCompanies,
    setCurrentCompanyId,
    setLoading,
    clearCompany,
  };
};

export default useCurrentCompany;
