<script setup>
import { watch } from 'vue';
import useCompanyOffers from './composables/useCompanyOffers';
import useCurrentCompany from './composables/useCurrentCompany';

const { currentCompanyId } = useCurrentCompany();
const { fetchAll } = useCompanyOffers();

watch(
  currentCompanyId,
  (value, oldValue) => {
    if (!value || value === oldValue) {
      return;
    }

    fetchAll(value);
  },
  { immediate: true },
);
</script>

<template>
  <router-view />
</template>
