const IS_FREE = 'free';
const MUST_BE_SET_UP = 'mustBeSetUp';
const IS_LOYALTY = 'loyalty';


export default {
  IS_FREE,
  MUST_BE_SET_UP,
  IS_LOYALTY,
};
