import { createRouter, createWebHistory } from 'vue-router';

import cookiesUtils from '@/utils/cookies.utils';
import cookiesConstants from '@/constants/cookies.constants';
import rolesConstants from '@/constants/roles.constants';
import routesConstants from '@/constants/routes.constants';

import adminRoutes from './admin';

const { ADMINISTRATOR, BACKOFFICE } = rolesConstants;

const routes = [
  {
    path: '/',
    redirect: routesConstants.HOME,
    meta: {
      requiresAuth: [ADMINISTRATOR, BACKOFFICE],
    },
  },
  {
    path: routesConstants.HOME,
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: {
      requiresAuth: [ADMINISTRATOR, BACKOFFICE],
    },
  },
  {
    path: routesConstants.SIGN_IN,
    name: 'SignIn',
    component: () => import('../views/Login.vue'),
  },
  {
    path: routesConstants.SIGN_UP,
    name: 'SignUp',
    component: () => import('../views/Register.vue'),
  },
  {
    path: routesConstants.SIGN_UP_SUCCESS,
    name: 'SignUpSuccess',
    component: () => import('../views/RegisterSuccess.vue'),
  },
  {
    path: routesConstants.CONFIRM_EMAIL,
    name: 'ConfirmEmail',
    component: () => import('../views/PasswordLost.vue'),
  },
  {
    path: routesConstants.PASSWORD_LOST,
    name: 'PasswordLost',
    component: () => import('../views/PasswordLost.vue'),
  },
  {
    path: routesConstants.INVOICES,
    name: 'Invoices',
    component: () => import('../views/Invoices.vue'),
    meta: {
      requiresAuth: [ADMINISTRATOR, BACKOFFICE],
    },
  },
  {
    path: routesConstants.PROFILE,
    name: 'Profile',
    component: () => import('../views/Profile.vue'),
    meta: {
      requiresAuth: [ADMINISTRATOR, BACKOFFICE],
    },
  },
  {
    path: routesConstants.CATALOG,
    name: 'Catalog',
    component: () => import('../views/Catalog.vue'),
    meta: {
      requiresAuth: [ADMINISTRATOR, BACKOFFICE],
    },
  },
  {
    path: routesConstants.CGV,
    name: 'CGV',
    component: () => import('../views/CGV.vue'),
    meta: {
      requiresAuth: [ADMINISTRATOR, BACKOFFICE],
    },
  },
  {
    path: routesConstants.NEW_ENTERPRISE,
    name: 'NewEnterprise',
    component: () => import('../views/CreateEnterprise.vue'),
    meta: {
      requiresAuth: [ADMINISTRATOR, BACKOFFICE],
    },
  },
  {
    path: routesConstants.OFFERS,
    name: 'Offers',
    component: () => import('../views/Offers.vue'),
    meta: {
      requiresAuth: [ADMINISTRATOR, BACKOFFICE],
    },
  },
  {
    path: routesConstants.SUBSCRIPTION_DETAILS,
    name: 'SubscriptionDetails',
    component: () => import('../views/subscriptions/SubscriptionDetails.vue'),
    meta: {
      requiresAuth: [ADMINISTRATOR, BACKOFFICE],
    },
  },
  {
    path: routesConstants.SUBSCRIPTION_SUCCESS,
    name: 'SubscriptionSuccess',
    component: () => import('../views/subscriptions/SubscriptionSuccess.vue'),
    meta: {
      requiresAuth: [ADMINISTRATOR, BACKOFFICE],
    },
  },
  {
    path: routesConstants.SUBSCRIPTION_CANCELED,
    name: 'SubscriptionCanceled',
    component: () => import('../views/subscriptions/SubscriptionCanceled.vue'),
    meta: {
      requiresAuth: [ADMINISTRATOR, BACKOFFICE],
    },
  },
  {
    path: routesConstants.LOYALTY_CARD_SCANNER,
    name: 'LoyaltyCardScanner',
    component: () => import('../views/LoyaltyCardReader.vue'),
    meta: {
      requiresAuth: [ADMINISTRATOR, BACKOFFICE],
    },
  },
  {
    path: routesConstants.ANNOUNCEMENTS,
    name: 'ManageAnnouncements',
    component: () => import('../views/Announcements.vue'),
    meta: {
      requiresAuth: [ADMINISTRATOR, BACKOFFICE],
    },
  },
  {
    path: routesConstants.ONLINE_SHOP_CONFIRMATION,
    name: 'OnlineShopConfirmation',
    component: () => import('../views/OnlineShopConfiguration.vue'),
  },
  {
    path: routesConstants.SUBSCRIPTION_RECURRENCE_SETUP,
    name: 'SubscriptionRecurrenceSetup',
    component: () => import('../views/SubscriptionRecurrenceSetup.vue'),
  },
  {
    path: routesConstants.PRINT_CLIENT_QRCODE,
    name: 'PrintClientQrCode',
    component: () => import('../views/PrintClientQrCode.vue'),
    meta: {
      requiresAuth: [ADMINISTRATOR, BACKOFFICE],
    },
  },
  ...adminRoutes,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const isConnected = !!cookiesUtils.getCookies(cookiesConstants.USER_INFOS);

  const canNavigate = to.matched.some((record) => {
    if (!record.meta.requiresAuth) {
      return true;
    }

    return cookiesUtils.isInRole(record.meta.requiresAuth);
  });

  if (canNavigate) {
    next();

    return;
  }

  if (!isConnected) {
    next(routesConstants.SIGN_IN);

    return;
  }

  next(routesConstants.HOME);
});

export default router;
