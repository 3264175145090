import cookiesConstants from '@/constants/cookies.constants';

/**
 * @param {string} name
 */
const getCookies = (name) => {
  if (!document.cookie?.length) {
    return null;
  }

  const rawCookies = document.cookie?.split(';');

  if (!rawCookies) {
    return null;
  }

  const cookies = rawCookies.reduce((acc, curr) => {
    const [key, value] = curr.split('=');

    return {
      ...acc,
      [key.trim()]: value,
    };
  }, {});

  return cookies[name];
};

const getUserInfos = () => {
  const userInfo = getCookies(cookiesConstants.USER_INFOS);

  if (!userInfo) {
    return null;
  }

  return JSON.parse(decodeURIComponent(userInfo));
};

const isInRole = (roles) => {
  const userInfos = getUserInfos();

  if (Array.isArray(roles)) {
    return roles.includes(userInfos?.role);
  }

  return roles === userInfos?.role;
};

export default {
  getCookies,
  getUserInfos,
  isInRole,
};
