import config from '@/config';

import httpService from '@/services/http.service';
import apiErrorUtils from '@/utils/apiErrors.utils';

const BASE_URL = config.lowcoApiUrl;

const mapError = (error) => {
  switch (error.code) {
    default:
      return 'errorMessages.general';
  }
};

const throwError = apiErrorUtils.makeThrowError(mapError);

const fetchCompanySubscriptionOptions = () => httpService
  .get(`${BASE_URL}/company-subscriptions`)
  .catch(throwError);

const fetchCompanySubscription = (companyId) =>
  httpService
    .get(`${BASE_URL}/companies/${companyId}/subscription`)
    .catch(throwError);

const fetchCompanySubscriptionHistory = (companyId, pageNumber, pageSize = 5) =>
  httpService
    .get(
      `${BASE_URL}/companies/${companyId}/subscription-history?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    )
    .catch(throwError);

const fetchFutureSubscription = (companyId, futureSubscriptionId) =>
  httpService
    .get(
      `${BASE_URL}/companies/${companyId}/subscription-infos?newSubscription=${futureSubscriptionId}`,
    )
    .catch(throwError);

const cancelSubscription = (companyId) =>
  httpService.put(`${BASE_URL}/companies/${companyId}/cancel-subscription`)
  .catch(throwError);

const cancelSubscriptionCancellation = (companyId) =>
  httpService
    .post(`${BASE_URL}/companies/${companyId}/cancel-subscription-cancellation`)
    .catch(throwError);

const importLegacyCompanyLoyaltyByAdmin = (companyId, file, legacyCardType) => {
  const formData = new FormData();

  formData.append('File', file, file.name);
  formData.append('LegacyLoyaltyCardType', legacyCardType);

  return httpService
    .postFormData(`${BASE_URL}/admin/companies/${companyId}/loyalty/import-legacy`, formData)
    .catch(throwError);
};

const importCompanyLegacyLoyalty = (companyId, file, legacyCardType) => {
  const formData = new FormData();

  formData.append('File', file, file.name);
  formData.append('LegacyLoyaltyCardType', legacyCardType);

  return httpService
    .postFormData(`${BASE_URL}/companies/${companyId}/loyalty/import-legacy`, formData)
    .catch(throwError);
};

const exportCompanyLoyalty = (companyId) =>
  httpService.get(`${BASE_URL}/companies/${companyId}/export`)
  .catch(throwError);

export default {
  fetchCompanySubscriptionOptions,
  fetchCompanySubscription,
  fetchCompanySubscriptionHistory,
  fetchFutureSubscription,
  cancelSubscription,
  cancelSubscriptionCancellation,
  importLegacyCompanyLoyaltyByAdmin,
  importCompanyLegacyLoyalty,
  exportCompanyLoyalty,
};
